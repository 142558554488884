import { db } from '@/utils/firebase.js'
import StoreCache from '@/utils/StoreCache.js'
const DEFAULT_LIFETIME = 1 * 60 * 60 * 1000 // 1 hour

const state = {
    whats_new: new StoreCache(DEFAULT_LIFETIME),
}

const getters = {
    whats_new: (state) => state.whats_new.cachedData() || {},
    news_last_version: (state, getters) => getters.whats_new.last_version || '',
    news_versions: (state, getters) => getters.whats_new.versions || [],
    has_news: (state, getters) => Boolean(getters.news_last_version && getters.news_versions.length),
}

const actions = {
    GetWhatsNew({ state, commit }) {
        const whats_new = state.whats_new

        if (!whats_new.expired()) {
            return Promise.resolve(whats_new.cachedData())
        }

        return db.collection('whats-new').get().then(whats_new_collection => {
            const whats_new = whats_new_collection?.docs?.find(doc => doc.id == 'web')?.data()

            if (!whats_new?.versions) {
                commit('RESET_WHATS_NEW')

                return Promise.resolve({})
            }

            whats_new.versions.sort((a, b) => b.index - a.index)

            whats_new.versions = whats_new.versions.slice(0, 3)

            commit('SAVE_WHATS_NEW', whats_new)

            return whats_new
        }).catch(error => {
            console.log('whats_new error', {error});

            commit('RESET_WHATS_NEW')
    
            return Promise.resolve({})
        })
    },
}

const mutations = {
    SAVE_WHATS_NEW(state, whats_new) {
        state.whats_new.fix(whats_new)
    },

    RESET_WHATS_NEW(state) {
        state.whats_new.reset()
    },
}

export default {
  	state,
  	getters,
  	mutations,
  	actions,
}
