import store from '@/store';

export default (to, from, next) => {
    const has_welcome_tour = store.getters.has_app_welcome_tour

    return {
        allow: has_welcome_tour,
        redirect: { name: 'not-found' },
    }
};
