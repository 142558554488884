<template>
    <AppModal 
        ref="app-modal-hot-reload"
        className="modal-hot-reload"
        @close-modal="$emit('close')"
        @onenter="$emit('refresh')"
    >
        <template #header>
            <p class="modal-hot-reload__icon icon icon--laptop"/>
            <h3 class="modal-hot-reload__title">{{$t('new_version_app_is_available')}}</h3>
        </template>

        <p class="modal-hot-reload__message">{{$t('We_excited_to_announce_a_new_app')}}</p>

        <div class="modal-hot-reload__buttons">
            <button class="modal-hot-reload__button btn" @click="$emit('refresh')">{{$t('refresh')}}</button>
            <button class="modal-hot-reload__button btn btn--secondary" @click="$emit('close')">{{$t('remind_later')}}</button>
        </div>
    </AppModal>
</template>

<script>
import AppModal from '@/components/app-modal'

export default { 
    components: {
        AppModal
    },

    mounted() {
        this.$refs['app-modal-hot-reload'].showModal()
    },
}
</script>

<style lang="scss">
.modal-hot-reload {
    z-index: 501;

    .app-modal__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 730px;
    }

    &__icon {
        font-size: 56px;
        color: $brand-color-primary;
        margin-bottom: 24px;

        @include media-bp(mob) {
            font-size: 48px;
        }
    }

    &__title {
        margin-bottom: 40px;

        @include media-bp(mob) {
            margin-bottom: 32px;
        }
    }

    &__message {
        margin-bottom: 48px;
        text-align: center;
        font-size: 24px;
        line-height: 1.33;
        color: $text-color-base;

        @include media-bp(mob) {
            margin-bottom: 32px;
            font-size: 18px;
        }
    }

    &__buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 30px;

        @include media-bp(mob) {
            gap: 24px;
        }
    }

    &__button {
        min-width: 220px;

        @include media-bp(mob) {
            min-width: 100%;
        }
    }
}
</style>
