<template>
    <app-modal :class-name="is_wide ? `dialog-confirmation dialog-confirmation--wide ${className}` : `dialog-confirmation ${className}`"
        ref="app-modal"
        :processing="processing"
        :error="error"
        @close-modal="hideModalCanceled"
    >
        <template v-if="has_header" #header>
            <slot name="header">
                <h3 v-html="title" />
            </slot>
        </template>

        <div>
            <div class="confirmation-message">
                <slot>
                    <p v-html="message"></p>
                </slot>
            </div>
            
            <div class="actions">
                <slot name="actions">
                    <button v-if="has_cancel_button" class="btn btn--secondary" :disabled="processing" @click="hideModalCanceled">{{ cancel_button_caption }}</button>
                    <button class="btn" :disabled="processing" @click="confirmed">{{ confirm_button_caption }}</button>
                </slot>
            </div>
        </div>
    </app-modal>
</template>

<script>
import dialog_auto_show from '@/mixins/dialog-auto-show'

export default {
    mixins: [dialog_auto_show],

    props: {
        confirmation_type:        { type: String,  default: ''                                },
        has_header:               { type: Boolean, default: true                              },
        has_cancel_button:        { type: Boolean, default: true                              },
        title:                    { type: String, default() {return this.$t('confirmation')}  },
        message:                  { type: String,  default: null                              },
        processing:               { type: Boolean, default: false                             },
        is_hide_modal_on_confirm: { type: Boolean, default: true                              },
        is_wide:                  { type: Boolean, default: false                              },
        error:                    { default: null                                             },
        confirm_button_caption:   { type: String,  default() {return this.$t('confirm')}      },
        cancel_button_caption:    { type: String,  default() {return this.$t('cancel')}       },
        className:                { default: ''                                               },
    },

    methods: {
        confirmed() {
            this.$emit('confirmed', this.confirmation_type)

            if (this.is_hide_modal_on_confirm) {
                this.hideModal()
    
                this.$store.commit('hideConfirmationModal', true)
            }
        },

        hideModalCanceled() {
            this.$emit('canceled')
            this.hideModal()

            this.$store.commit('hideConfirmationModal', false)
        },
    },
}
</script>

<style lang="scss">
.dialog-confirmation {
    .app-modal__header {
        margin: 0 0 40px;

        h3 {
            margin: 0 0 16px;

            &:last-child { margin: 0; }
        }

        h5 {
            font-size: 24px;
            line-height: 32px;
        }
    }

    .confirmation-message {
        font-size: 24px;
        text-align: center;
    }

    .actions {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 48px 0 0;

        .btn {
            flex-grow: 1;
            width: auto;
            flex-basis: auto;
            max-width: 220px;
            margin: 0 15px;

            &:first-child { margin-left: 0; }
            &:last-child { margin-right: 0; }
        }
    }

    &--wide {
        .app-modal__body {
            width: 730px;
        }
    }
}

@media (max-width: $tablet-size) {
    .dialog-confirmation {
        .app-modal__header {
            margin-bottom: 32px;

            h5 {
                font-size: 20px;
                line-height: 28px;
            }
        }

        .confirmation-message {
            font-size: 18px;
            text-align: center;
        }
    }
}

@media (max-width: $mobile-size) {
    .dialog-confirmation {
        .app-modal__header {
            h5 {
                font-size: 18px;
                line-height: 24px;
            }
        }

        .confirmation-message {
            font-size: 16px;
            text-align: center;
        }

        .actions {
            flex-direction: column;
            margin-top: 32px;

            .btn {
                width: 100%;
                max-width: 100%;
                margin: 24px 0 0;

                &:first-child { margin: 0; }
            }
        }
    }
}
</style>