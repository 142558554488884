import { db } from '@/utils/firebase.js'
import dynStorage from '@/utils/storage-dynamic'

const state = {
    mockup_user: {},
}

const getters = {
	mockup_user: (state) => state.mockup_user,
	mockup_customer: (state, getters) => state.mockup_user?.[getters.current_account_uid] || {},

    top_up_auto: (state, getters) => getters.mockup_customer?.['top-up-auto'],
    is_mockup_customer_hide_external_ID_promt: (state, getters) => Boolean(getters.mockup_customer?.['HideExternalIDPromt']),
    onboarding: (state, getters) => getters.mockup_customer?.['onboarding'],
    mockup_user_language: (state, getters) => getters.mockup_user?.['lang'],
    is_accepted_terms: (state, getters) => getters.mockup_user?.['is-accepted-terms'],

    mockup_user_is_welcome_tour_shown: (state) => Boolean(state.mockup_user?.is_web_welcome_tour_shown),
    mockup_user_v_tour: (state) => state.mockup_user?.v_tour || {},
    mockup_user_shown_whats_new_last_version: (state, getters) => getters.mockup_user?.shown_whats_new_last_version || null,
    mockup_user_shown_features_versions: (state, getters) => getters.mockup_user?.shown_features_versions || [],
}

const mutations = {
	saveMockupUser(state, mockup_user) {

    	state.mockup_user = mockup_user

    	if (mockup_user) {
        	dynStorage.set('mockup-user', mockup_user, null, true)
    	} else {
        	dynStorage.remove('mockup-user')
    	}
  	},
}

const actions = {
	mockupCustomer({ getters, commit, dispatch }) {
    	const user_uuid = getters.auth_user?.uid
    	const customer_uuid = getters.current_account_uid

    	if (user_uuid) {
      		return db
        	.doc(`users/${user_uuid}`)
        	.get()
        	.then((user) =>
          		user.exists
            	? Promise.resolve(user)
            	: db
                	.collection('users')
                	.doc(user_uuid)
                	.set({})
                	.then(() => db.doc(`users/${user_uuid}`).get()),
        	)
        	.then((user) => {
          		if (!customer_uuid) return Promise.resolve(user)

          		const mockup_user = user.data()

          		return !(customer_uuid in mockup_user)
            		? db
                		.collection('users')
                		.doc(user_uuid)
                		.set({
                  			...mockup_user,
                  			[customer_uuid]: {
                    			notifications: [],
                    			policy: true,
                    			'top-up-auto': {
                      				amount: 0,
                      				days: 0,
                      				status: false,
                      				type: 'EVERY_DAY',
                    			},
                  			},
                		})
                		.then(() => db.doc(`users/${user_uuid}`).get())
            		: Promise.resolve(user)
        	})
        	.then((user) => {
          		commit('saveMockupUser', user.data())

          		const mockup_user_language = getters.mockup_user_language
          		const local_storage_language = dynStorage.get('language', true)

          		if (local_storage_language && mockup_user_language !== local_storage_language) {
              		dispatch('updateMockupUser', { 'lang': local_storage_language })
          		} else if (!local_storage_language && mockup_user_language) {
              		dispatch('SetLanguage', { language: mockup_user_language, updateMockupUserLanguage: false})
          		}

          		return Promise.resolve(getters.mockup_customer)

        	})
        	.catch((error) => {
          		console.warn('mockupCustomer error', error)
          		console.dir(error)

          		return Promise.resolve(getters.mockup_customer)
        	})
    	} else {
      		return Promise.resolve(getters.mockup_customer)
    	}
  	},

  	saveMockupCustomer({ getters, commit, dispatch }, data) {
    	const user_uuid = getters.auth_user?.uid
    	const customer_uuid = getters.current_account_uid

    	const mockup_user = getters.mockup_user
    	const mockup_customer = getters.mockup_customer

    	const customer_data = {
      		...mockup_customer,
      		...data,
    	}

    	const user = {
      		...mockup_user,
      		[customer_uuid]: customer_data,
    	}

    	return db
      			.collection('users')
      			.doc(user_uuid)
      			.update(user)
      			.then(() => {
        			commit('saveMockupUser', {
          				...user,
          				[customer_uuid]: {
            				...customer_data,
          				},
        			})
      			})
  	},

  	updateMockupUser({ getters, commit }, data) {
    	const user_uuid = getters.auth_user?.uid
    	const mockup_user = getters.mockup_user

    	const user = {
        	...mockup_user,
        	...data,
      	}

    	return db
      			.collection('users')
      			.doc(user_uuid)
      			.update(user)
      			.then(() => {
        			commit('saveMockupUser', {
          				...user,
        			})
      			})
      			.catch(error => {
        			console.log('updateMockupUser error', error)
        			return Promise.reject(error)
      			})
  	},

  	saveMockupAutoTopUp({ dispatch }, top_up_auto) {
    	return dispatch('saveMockupCustomer', { 'top-up-auto': top_up_auto })
  	},

  	saveMockupLanguage({ dispatch }, language) {
    	return dispatch('updateMockupUser', { 'lang': language })
  	},

    saveMockupHideExternalIDPromt({ dispatch }, HideExternalIDPromt) {
    	return dispatch('saveMockupCustomer', { HideExternalIDPromt })
  	},

    saveMockupIsWelcomeTourShown({ dispatch }, is_web_welcome_tour_shown) {
    	return dispatch('updateMockupUser', { is_web_welcome_tour_shown }).catch(()=>{})
    },
    
    saveMockupVTour({ dispatch }, v_tour) {
    	return dispatch('updateMockupUser', { v_tour }).catch(()=>{})
    },
    
    saveMockupShownWhatsNew({ dispatch }, mockup_user_whats_new) {
        if (!mockup_user_whats_new) return Promise.resolve();

    	return dispatch('updateMockupUser', mockup_user_whats_new)
  	},
}

export default {
  	state,
  	getters,
  	mutations,
  	actions,
}
